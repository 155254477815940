var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "singleTableList" }, [
    _c(
      "ul",
      { staticClass: "childlist" },
      _vm._l(_vm.fieldsList, function(item, index) {
        return _c("li", { key: index, class: item.class }, [
          _vm._v(_vm._s(item.name))
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "allparentlist" },
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "ul",
          { key: index, staticClass: "childcontentlist" },
          _vm._l(_vm.data.data, function(sItem, i) {
            return _c(
              "li",
              {
                key: i,
                class: sItem.class,
                attrs: { title: item[sItem.field] }
              },
              [
                sItem.isIndex
                  ? _c("span", [_vm._v(_vm._s(index + 1))])
                  : sItem.isAction
                  ? _c("div", [
                      item.actions.hasEdit
                        ? _c("i", {
                            staticClass: "el-icon-edit",
                            attrs: { type: "primary", title: "编辑" },
                            on: {
                              click: function($event) {
                                return _vm.editHandle(item)
                              }
                            }
                          })
                        : _vm._e(),
                      item.actions.hasDelete
                        ? _c("i", {
                            staticClass: "el-icon-delete",
                            attrs: { type: "primary", title: "删除" },
                            on: {
                              click: function($event) {
                                return _vm.deleteHandle(item)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  : _c("span", [_vm._v(_vm._s(item[sItem.field]))])
              ]
            )
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }